.list {
  display: none;
}

.list_active {
  top: 110%;
  list-style: none;
  position: absolute;
  background-color: #232323;
  border-radius: 16px;
  flex-direction: column;
  padding: 8px 24px;
  display: flex;
  width: 100%;
  right: 0;
  z-index: 1000;
}

.main_container {
  color: #fff;
  background-color: #303030;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 8px;
  flex: none;
  align-self: center;
  padding: 10px 14px 11px;
  line-height: 1.5;
  text-decoration: none;
  cursor: pointer;
}

.link {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
  padding: 20px 0;
  text-decoration: none;
}

.lang__wrapper__button {
  color: #fff;
  white-space: nowrap;
  background-color: #303030;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 8px;
  flex: none;
  align-self: center;
  padding: 10px 14px 11px;
  line-height: 1.5;
  position: static;
  text-decoration: none;
  cursor: pointer;
  margin: 0 5px;
}

.lang__wrapper__button__active {
  background-color: #fff;
  color: #303030;
  border: 1px solid #232323;
  border-radius: 8px;
  flex: none;
  align-self: center;
  padding: 10px 14px 11px;
  line-height: 1.5;
  position: static;
  text-decoration: none;
  cursor: pointer;
}

.lang__wrapper {
  padding: 10px 0;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 781px) {
  .main_container {
    /* Update styles for smaller  */
    padding: 8px 14px;
    font-size: 16px;
  }

  .list {
    /* Update styles for dropdown on smaller screens */
    padding: 8px;
  }
}

/* @media screen and (max-width: 479px) {
} */
