.form {
  flex-flow: column wrap;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 2fr;
  grid-template-rows: 1fr 0.3fr 1fr 0.3fr 1fr 0.3fr 2fr repeat(2, 0.3fr) 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.form input {
  color: #232323;
  background-color: #fff;
  border: 1px solid #232323;
  border-radius: 8px;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
}

.form__name {
  width: 50%;
  height: 60px;
  grid-area: 1 / 1 / 2 / 2;
}

.form__error__name {
  grid-area: 2 / 1 / 3 / 2;
}

.form__email {
  width: 50%;
  height: 60px;
  grid-area: 3 / 1 / 4 / 2;
}

.form__error__email {
  grid-area: 4 / 1 / 5 / 2;
}

.form__message {
  height: 120px;
  resize: none;
  color: #232323;
  background-color: #fff;
  border: 1px solid #232323;
  border-radius: 8px;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  grid-area: 7 / 1 / 8 / 2;
}

.form__error__message {
  grid-area: 8 / 1 / 9 / 2;
}

.form__phone {
  width: 50%;
  height: 60px;
  grid-area: 5 / 1 / 6 / 2;
}

.form input:hover {
  border: 2px solid #232323;
}

.form textarea:hover {
  border: 2px solid #232323;
}

.form__button {
  color: #fff;
  white-space: nowrap;
  background-color: #303030;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 8px;
  flex: none;
  align-self: center;
  padding: 10px 14px 11px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  position: static;
  text-decoration: none;
  cursor: pointer;
}

.form__button:hover {
  background-color: #fff;
  color: #303030;
  border: 1px solid #232323;
}

.form p {
  color: red;
  font-size: 14px;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}

.form__checkbox__wrapper a {
  color: black;
}

.bottom__wrapper {
  grid-area: 10 / 1 / 11 / 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.agreement__wrapper {
  grid-area: 9 / 1 / 10 / 2;
  display: flex;
}

.form__error__agreement {
  margin-left: 10px;
}

.private__policy__link {
  font-size: 14px;
}

.private__policy__link a {
  color: black;
}

/* mobile responsive */

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 781px) {
  .form__name {
    width: 100%;
  }

  .form__email {
    width: 100%;
  }

  .form__message {
    width: 100%;
  }

  .form__phone {
    width: 100%;
  }

  .private__policy__link {
    font-size: 14px;
  }
}

@media screen and (max-width: 479px) {
  .bottom__wrapper {
    flex-direction: column;
  }

  .private__policy__link {
    margin-bottom: 9px;
  }

  .form {
    grid-template-rows: 0fr 0.3fr 0fr 0.3fr 0fr 0.3fr 1fr repeat(2, 0.3fr) 0fr;
  }

  .agreement__wrapper {
    align-items: center;
    flex-direction: row;
  }
}
