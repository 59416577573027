#SwiperComponent {
  height: 100%;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
  border-radius: 2em;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide .title {
  position: absolute;
}

.one {
  left: 5px;
  top: 15px;
  width: 50%;
  font-size: 50px;
  font-weight: 900;
  -webkit-background-clip: text;
  color: transparent;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: text;
  background-color: #f9484a;
  background-image: linear-gradient(315deg, #f9484a 0%, #fbd72b 74%);
}

.two {
  top: 15px;
  right: 5px;
  width: 70%;
  font-size: 50px;
  font-weight: 900;
  -webkit-background-clip: text;
  color: transparent;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: text;
  background-color: #2a2a72;
  background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
}

.three {
  top: 50px;
  right: 5px;
  width: 50%;
  font-size: 50px;
  font-weight: 900;
  -webkit-background-clip: text;
  color: transparent;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: text;
  background-color: #63d471;
  background-image: linear-gradient(315deg, #233329 0%, #63d471 74%);
}

.text {
  opacity: 1;
  animation: fade-in 1.7s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 991px) {
  .one {
    font-size: 60px;
    font-weight: 700;
  }

  .two {
    font-size: 50px;
    font-weight: 700;
  }

  .three {
    font-size: 50px;
    font-weight: 700;
  }
}

@media screen and (max-width: 781px) {
  .one {
    font-size: 40px;
    font-weight: 700;
    top: 50px;
  }

  .two {
    font-size: 40px;
    font-weight: 700;
    width: 75%;
    top: 50px;
    right: 50px;
  }

  .three {
    font-size: 40px;
    font-weight: 700;
    width: 75%;
    top: 50px;
    right: 5px;
  }
}

@media screen and (max-width: 479px) {
  .one {
    font-size: 25px;
    font-weight: 700;
    top: 50px;
  }

  .two {
    font-size: 25px;
    font-weight: 700;
    width: 75%;
    top: 50px;
    right: 50px;
  }

  .three {
    font-size: 25px;
    font-weight: 700;
    width: 75%;
    top: 50px;
    right: 5px;
  }
}
